import React, {Component} from "react";
import ReactLoading from 'react-loading';

class LoadingScreen extends Component{
    render() {
        return(
            <ReactLoading type={"bars"} color={"#000"} width={"60px"} height={"60px"} className={"loading-bars"}/>
        );
    }
}

export default LoadingScreen;